import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from './media.module.scss';
import {
    Formats,
    StrapiCoverImageComponentProps,
    StrapiImage,
    StrapiImageComponentProps,
    ImageProps,
} from '../../Models/MediaProps';

export const MediaComponent = ({
    maxWidth,
    maxHeight,
    src,
    alt,
    minWidth = null,
    isVideo = false,
    loop = true,
    transitionDelay = 200,
    extraClassNames = '',
}: ImageProps) => {
    if (isVideo) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <video className={styles.videoContainer} autoPlay loop={loop} muted playsInline>
                    <source src={generateSrcUrl(src, false)} type="video/mp4" />
                </video>
            </div>
        );
    } else {
        return (
            <div className={`${extraClassNames}`} style={{ maxWidth, maxHeight, minWidth }}>
                <LazyLoadImage
                    src={generateSrcUrl(src, false)}
                    alt={alt}
                    style={{ maxWidth: '100%', maxHeight }}
                    effect="blur"
                    placeholderSrc={generateSrcUrl(src, false)}
                    wrapperProps={{
                        style: { transitionDelay: `${transitionDelay}ms` },
                    }}
                />
            </div>
        );
    }
};

export const StrapiImageComponent = ({
    maxWidth,
    maxHeight,
    strapiImage,
    className,
    transitionDelay = 200,
    children,
}: StrapiImageComponentProps) => {
    if (strapiImage) {
        if (strapiImage.formats) {
            return (
                <div className={className} style={{ maxWidth, maxHeight }}>
                    <LazyLoadImage
                        src={generateStrapiSrcUrl(strapiImage)}
                        alt={`Picture of ${strapiImage.alternativeText}`}
                        style={{ maxWidth: '100%', maxHeight }}
                        effect="blur"
                        placeholderSrc={generateStrapiSrcUrl(strapiImage)}
                        wrapperProps={{
                            style: { transitionDelay: `${transitionDelay}ms` },
                        }}
                    />
                    {children || null}
                </div>
            );
        } else {
            return (
                <div className={className} style={{ maxWidth, maxHeight }}>
                    <LazyLoadImage
                        src={generateSrcUrl(strapiImage.url, true)}
                        alt={`Picture of  ${strapiImage.alternativeText}`}
                        style={{ maxWidth: '100%', maxHeight }}
                        effect="blur"
                        placeholderSrc={generateSrcUrl(strapiImage.url, true)}
                        wrapperProps={{
                            style: { transitionDelay: `${transitionDelay}ms` },
                        }}
                    />
                    {children || null}
                </div>
            );
        }
    }

    return null;
};

export const StrapiCoverImageComponent = ({
    styles,
    className,
    strapiImage,
    children,
}: StrapiCoverImageComponentProps) => {
    if (strapiImage) {
        return (
            <div
                className={className}
                style={{
                    backgroundImage: 'url(' + generateSrcUrl(strapiImage.url, true) + ')',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    ...styles,
                }}
            >
                {children || null}
            </div>
        );
    }

    return null;
};

const generateStrapiSrcUrl = (strapiImage: StrapiImage) => {
    return `${process.env.NEXT_PUBLIC_IMAGE_URL}${strapiImage.url}`;
};

const generateSrcUrl = (src: string, isStrapiMedia: boolean) => {
    if (src) {
        if (isStrapiMedia) {
            return `${process.env.NEXT_PUBLIC_IMAGE_URL}${src}`;
        } else {
            return `${process.env.NEXT_PUBLIC_WEBSITE_URL}${src}`;
        }
    } else {
        return null;
    }
};

const generateSrcSet = (formats: Formats) => {
    let finalSrcSet = '';
    if (formats.large?.url) {
        const large = generateSrcUrl(formats.large.url, true);
        finalSrcSet += `${large} 1500w, `;
    }
    if (formats.medium?.url) {
        const medium = generateSrcUrl(formats.medium.url, true);
        finalSrcSet += `${medium} 750w, `;
    }
    if (formats.small?.url) {
        const small = generateSrcUrl(formats.small.url, true);

        finalSrcSet += `${small} 500w, `;
    }
    return finalSrcSet;
};
