import React, { useEffect } from 'react';
import activeDropdown from './ActiveLink/activeDropdown';
import ActiveLink from './ActiveLink/ActiveLink';
import styles from './header.module.scss';
import HeaderArrow from './icons/HeaderArrow';

type HeaderProps = {
    setBackground: Function;
};

const DesktopHeaderComponent = ({ setBackground }: HeaderProps) => {
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                setBackground(styles.background);
            } else {
                setBackground(styles.noBackground);
            }
        });
    }, []);

    return (
        <div className={styles.navbar}>
            <button className={styles.dropdownMenu}>
                <span
                    className={`${styles.dropdownNavElement} ${
                        activeDropdown() === 'about-us' ? 'active' : ''
                    }`}
                >
                    Company
                    <HeaderArrow />
                </span>
                <div className={`${styles.dropdownSubmenu} ${styles.companyDropdownSubmenu}`}>
                    <div className={styles.dropdownSubmenuItems}>
                        <ActiveLink href="/about-us">
                            <span>About Noria</span>
                        </ActiveLink>
                        <ActiveLink href="/about-us/management-team">
                            <span>Management team</span>
                        </ActiveLink>
                    </div>
                </div>
            </button>
            <button className={styles.dropdownMenu}>
                <span
                    className={`${styles.dropdownNavElement} ${
                        activeDropdown() === 'offerings' ? 'active' : ''
                    }`}
                >
                    Offerings
                    <HeaderArrow />
                </span>
                <div className={`${styles.dropdownSubmenu} ${styles.offeringsDropdownSubmenu}`}>
                    <div className={styles.dropdownSubmenuItems}>
                        <ActiveLink href="/solutions" className={styles.dropdownSubmenuHeadTitle}>
                            <span>Insurance Solutions</span>
                        </ActiveLink>
                        <ActiveLink href="/solutions/marine-and-energy-insurance">
                            <span>Marine & Energy</span>
                        </ActiveLink>
                        <ActiveLink href="/solutions/captive-insurance">
                            <span>Captives</span>
                        </ActiveLink>
                        <ActiveLink href="/solutions/property-and-casualty-insurance">
                            <span>Property & Casualty</span>
                        </ActiveLink>
                        <ActiveLink href="/solutions">
                            <span>All solutions</span>
                        </ActiveLink>
                    </div>

                    <div className={styles.dropdownSubmenuItems}>
                        <ActiveLink href="/services" className={styles.dropdownSubmenuHeadTitle}>
                            <span>Services</span>
                        </ActiveLink>
                        <div className={styles.dropdownSubmenuHeadTitle}></div>
                        <ActiveLink href="/services/digital-strategy">
                            <span>Digital Strategy</span>
                        </ActiveLink>
                        <ActiveLink href="/services/transformation-delivery">
                            <span>Transformation Delivery</span>
                        </ActiveLink>
                        <ActiveLink href="/services/software-engineering-and-solution-architecture">
                            <span>
                                Software Engineering &<br /> Solution Architecture
                            </span>
                        </ActiveLink>
                        <ActiveLink href="/services/insights-and-analytics">
                            <span>Insights & Analytics</span>
                        </ActiveLink>
                        <ActiveLink href="/services/quality-assurance-and-test-engineering">
                            <span>
                                Quality Assurance &<br /> Test Engineering
                            </span>
                        </ActiveLink>
                        <ActiveLink href="/services/cloud-and-devops">
                            <span>Cloud & Devops</span>
                        </ActiveLink>
                        <ActiveLink href="/services/cloud-operations">
                            <span>Cloud Operations</span>
                        </ActiveLink>
                    </div>
                </div>
            </button>
            <ActiveLink className={styles.navElement} href="/resources">
                <span>Resources</span>
            </ActiveLink>
            <ActiveLink className={styles.navElement} href="/contact">
                <span>Contact</span>
            </ActiveLink>
            <a
                className={`${styles.navElement} ${styles.joinUs}`}
                href="https://career.noria.no"
                target="_blank"
            >
                Join us
            </a>
        </div>
    );
};

export default DesktopHeaderComponent;
