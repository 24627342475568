import Head from 'next/head';
import React, { ReactNode, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Footer from '../Footer/FooterComponent';
import HeaderComponent from '../Header/HeaderComponent';
import CookieBannerComponent, {
    acceptPolicyCookie,
} from '../Privacy/CookieBanner/CookieBannerComponent';
import { SeoMetaInformationProps } from '../util/SeoMetaInformationComponent/SeoMetaInformationComponent';
import styles from './layout.module.scss';

type LayoutProps = {
    children: ReactNode;
    seoMetadata?: SeoMetaInformationProps;
    defaultMetaTitle?: string;
    defaultMetaDescription?: string;
    defaultMetaKeywords?: string;
};

const Layout = ({
    children,
    seoMetadata,
    defaultMetaTitle,
    defaultMetaDescription,
    defaultMetaKeywords,
}: LayoutProps) => {
    const [hideCookieBanner, toggleCookieBanner] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies([acceptPolicyCookie]);
    const hasAcceptedCookiePolicy = cookies[acceptPolicyCookie];

    useEffect(() => {
        toggleCookieBanner(hasAcceptedCookiePolicy !== undefined);
        if (hasAcceptedCookiePolicy === 'required' || hasAcceptedCookiePolicy === undefined) {
            for (const key in cookies) {
                if (Object.prototype.hasOwnProperty.call(cookies, key)) {
                    if (key !== acceptPolicyCookie) {
                        // @ts-ignore
                        removeCookie(key);
                    }
                }
            }
        }
    });

    const renderDefaultMetadata = (title: string, description: string, keywords: string) => {
        if (title || description || keywords) {
            return (
                <Head>
                    {title && (
                        <>
                            <title key="noria-title">{defaultMetaTitle}</title>
                            <meta
                                property="og:title"
                                content={defaultMetaTitle}
                                key="noria-og-title"
                            />
                            <meta
                                property="twitter:title"
                                content={defaultMetaTitle}
                                key="noria-og-title-twitter"
                            />
                        </>
                    )}
                    {description && (
                        <>
                            <meta name="description" content={description} key="noria-desc" />
                            <meta
                                property="og:description"
                                content={description}
                                key="noria-og-desc"
                            />
                            <meta
                                property="twitter:description"
                                content={description}
                                key="noria-og-desc-twitter"
                            />
                        </>
                    )}
                    {keywords && <meta name="keywords" content={keywords} key="noria-keywords" />}
                </Head>
            );
        }

        return null;
    };

    const renderMetadataFromServer = (seoMetadata: SeoMetaInformationProps) => {
        if (seoMetadata) {
            const seoImageUrl = seoMetadata?.metaImage?.picture?.url
                ? `${process.env.NEXT_PUBLIC_IMAGE_URL}${seoMetadata.metaImage.picture.url}`
                : '';

            return (
                <Head>
                    {seoMetadata.metaTitle && (
                        <>
                            <title key="noria-title">{seoMetadata.metaTitle}</title>
                            <meta
                                property="og:title"
                                content={seoMetadata.metaTitle}
                                key="noria-og-title"
                            />
                            <meta
                                property="twitter:title"
                                content={seoMetadata.metaTitle}
                                key="noria-og-title-twitter"
                            />
                        </>
                    )}

                    {seoMetadata.metaDescription && (
                        <>
                            <meta
                                name="description"
                                content={seoMetadata.metaDescription}
                                key="noria-desc"
                            />
                            <meta
                                property="og:description"
                                content={seoMetadata.metaDescription}
                                key="noria-og-desc"
                            />
                            <meta
                                property="twitter:description"
                                content={seoMetadata.metaDescription}
                                key="noria-og-desc-twitter"
                            />
                        </>
                    )}

                    {seoMetadata.keywords && (
                        <meta name="keywords" content={seoMetadata.keywords} key="noria-keywords" />
                    )}
                    {seoMetadata.metaImage !== null && (
                        <>
                            <meta property="og:image" content={seoImageUrl} key="noria-og-image" />
                            <meta
                                property="twitter:image"
                                content={seoImageUrl}
                                key="noria-og-image-twitter"
                            />
                        </>
                    )}
                    {seoMetadata.preventIndexing === true && (
                        <meta name="robots" content="noindex,nofollow" />
                    )}
                    {seoMetadata.preventIndexing === true && (
                        <meta name="googlebot" content="noindex,nofollow" />
                    )}
                </Head>
            );
        }

        return null;
    };

    return (
        <>
            {renderDefaultMetadata(defaultMetaTitle, defaultMetaDescription, defaultMetaKeywords)}
            {renderMetadataFromServer(seoMetadata)}
            {hideCookieBanner ? null : <CookieBannerComponent />}
            <HeaderComponent />
            <main>{children}</main>
            <Footer />
        </>
    );
};

export default Layout;
