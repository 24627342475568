import Link from 'next/link';
import React, { CSSProperties, ReactNode } from 'react';
import DefaultWidthContainer from '../../Layout/Containers/DefaultWidthContainer';
import styles from './pageHeader.module.scss';

type PageHeaderProps = {
    children: ReactNode;
    extraClassNames?: string;
    heading?: string;
    quote?: string;
    extraStyles?: CSSProperties;
    hideQuoteOnMobile?: boolean;
    quoteClassNames?: string;
    buttonText?: string;
};

const PageHeaderComponent = ({
    extraClassNames = '',
    extraStyles,
    hideQuoteOnMobile = false,
    children,
    heading = '',
    quote = '',
    quoteClassNames = '',
    buttonText = '',
}: PageHeaderProps) => (
    <DefaultWidthContainer
        extraClassNames={`${styles.ingressContainer} ${extraClassNames}`}
        extraStyles={extraStyles}
    >
        {heading && <h1 style={{ marginBottom: '30px' }}>{heading}</h1>}
        <div className={styles.flex}>
            <div className={styles.ingress}>{children}</div>
            {quote && (
                <div className={quoteClassNames}>
                    <p
                        className={`${styles.quote} ${hideQuoteOnMobile ? styles.hidden : ''}`}
                        dangerouslySetInnerHTML={{ __html: quote }}
                    />
                    {buttonText && (
                        <Link
                            href="/solutions-and-services"
                            className={`button-link ${styles.button}`}
                        >
                            <span>{buttonText}</span>
                        </Link>
                    )}
                </div>
            )}
        </div>
    </DefaultWidthContainer>
);

export default PageHeaderComponent;
