import React, { useEffect, useState } from 'react';
import activeDropdown from './ActiveLink/activeDropdown';
import ActiveLink from './ActiveLink/ActiveLink';
import styles from './header.module.scss';
import HeaderArrow from './icons/HeaderArrow';

type HeaderProps = {
    setBackground: Function;
};

const MobileHeaderComponent = ({ setBackground: setBackgroundColor }: HeaderProps) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [offeringsOpen, setOfferingsOpen] = useState(false);
    const [aboutUsOpen, setAboutUsOpen] = useState(false);

    useEffect(() => {
        document.body.style.overflowY = menuOpen ? 'hidden' : 'scroll';
        setBackgroundColor(!menuOpen ? styles.noBgNoFade : styles.bgNoFade);
    }, [menuOpen]);

    const onClick = () => setMenuOpen(!menuOpen);
    const onOfferingsClick = () => setOfferingsOpen(!offeringsOpen);
    const onAboutUsClick = () => setAboutUsOpen(!aboutUsOpen);

    return (
        <>
            <button
                onClick={onClick}
                aria-label="Open the navigation menu"
                className={`${styles.hamburgerButton} ${
                    menuOpen ? styles.hamburgerButtonOpen : ''
                }`}
            >
                <span></span>
                <span></span>
            </button>
            <div
                className={`${styles.hamburgerMenu} ${menuOpen ? styles.hamburgerMenuOpen : ''}`}
                aria-label="Navigation menu"
            >
                <button className={styles.navElement} onClick={onAboutUsClick}>
                    <span
                        className={`${styles.navElementDropdown} ${
                            aboutUsOpen ? styles.navElementDropdownOpen : ''
                        } ${activeDropdown() === 'about-us' ? 'active' : ''}`}
                    >
                        Company
                        <HeaderArrow />
                    </span>
                </button>
                <div
                    className={`${styles.navElementSubmenu} ${
                        aboutUsOpen ? styles.navElementSubmenuOpen : ''
                    }`}
                >
                    <ActiveLink href="/about-us">
                        <span>About Noria</span>
                    </ActiveLink>
                    <ActiveLink href="/about-us/management-team">
                        <span>Management team</span>
                    </ActiveLink>
                </div>
                <button className={styles.navElement} onClick={onOfferingsClick}>
                    <span
                        className={`${styles.navElementDropdown} ${
                            offeringsOpen ? styles.navElementDropdownOpen : ''
                        } ${activeDropdown() === 'offerings' ? 'active' : ''}`}
                    >
                        Offerings
                        <HeaderArrow />
                    </span>
                </button>
                <div
                    className={`${styles.navElementSubmenu} ${
                        offeringsOpen ? styles.navElementSubmenuOpen : ''
                    }`}
                >
                    <div className={styles.navElementSubmenuItems}>
                        <ActiveLink href="/solutions" className={styles.navElementSubmenuHeadTitle}>
                            <span>Insurance Solutions</span>
                        </ActiveLink>
                        <ActiveLink href="/solutions/marine-and-energy-insurance">
                            <span>Marine & Energy</span>
                        </ActiveLink>
                        <ActiveLink href="/solutions/captive-insurance">
                            <span>Captives</span>
                        </ActiveLink>
                        <ActiveLink href="/solutions/property-and-casualty-insurance">
                            <span>Property & Casualty</span>
                        </ActiveLink>
                        <ActiveLink href="/solutions">
                            <span>All solutions</span>
                        </ActiveLink>
                    </div>

                    <div className={styles.navElementSubmenuItems}>
                        <ActiveLink href="/services" className={styles.navElementSubmenuHeadTitle}>
                            <span>Services</span>
                        </ActiveLink>
                        <ActiveLink href="/services/digital-strategy">
                            <span>Digital Strategy</span>
                        </ActiveLink>
                        <ActiveLink href="/services/transformation-delivery">
                            <span>Transformation Delivery</span>
                        </ActiveLink>
                        <ActiveLink href="/services/software-engineering-and-solution-architecture">
                            <span>
                                Software Engineering &<br /> Solution Architecture
                            </span>
                        </ActiveLink>
                        <ActiveLink href="/services/insights-and-analytics">
                            <span>Insights & Analytics</span>
                        </ActiveLink>
                        <ActiveLink href="/services/quality-assurance-and-test-engineering">
                            <span>
                                Quality Assurance &<br /> Test Engineering
                            </span>
                        </ActiveLink>
                        <ActiveLink href="/services/cloud-and-devops">
                            <span>Cloud & Devops</span>
                        </ActiveLink>
                        <ActiveLink href="/services/cloud-operations">
                            <span>Cloud Operations</span>
                        </ActiveLink>
                    </div>
                </div>
                <ActiveLink className={styles.navElement} href="/resources">
                    <span>Resources</span>
                </ActiveLink>
                <ActiveLink className={styles.navElement} href="/contact">
                    <span>Contact</span>
                </ActiveLink>
                <a
                    className={`${styles.navElement} ${styles.joinUs}`}
                    href="https://career.noria.no"
                    target="_blank"
                >
                    Join us
                </a>
            </div>
        </>
    );
};

export default MobileHeaderComponent;
