import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { isBrowser, isMobileOnly, isTablet } from 'react-device-detect';
import { HomeProps } from '../../pages';
import DefaultWidthContainer from '../Layout/Containers/DefaultWidthContainer';
import FullWidthContainer from '../Layout/Containers/FullWidthContainer';
import LargeWidthContainer from '../Layout/Containers/LargeWidthContainer';
import TripleColumnContainer from '../Layout/Containers/TripleColumnContainer';
import PageHeaderComponent from '../util/PageHeader/PageHeaderComponent';
import styles from './home.module.scss';

const DynamicResourceComponent = dynamic(() => import('../Resources/Resource/ResourceComponent'));

const HomeComponent = ({ works, resources }: HomeProps) => {
    const [hasWorks, setWorks] = useState(false);
    const [hasResources, setResources] = useState(false);

    useEffect(() => {
        setWorks(works.length > 0);
        setResources(resources.length > 0);
    });

    const renderWorks = () => {
        if (works.length < 0) {
            return null;
        } else {
            return works.map((work) => (
                <DynamicResourceComponent
                    key={`noria-work-${work.seoUrl}`}
                    mainImage={work.mainImage}
                    heading={work.heading}
                    ingress={work.ingress}
                    resource_category={work.resource_category}
                    seoUrl={work.seoUrl}
                    isShowAuthor={false}
                />
            ));
        }
    };

    const renderResources = () => {
        if (resources.length < 0) {
            return null;
        } else {
            return resources.map((resource) => (
                <DynamicResourceComponent
                    key={`noria-resource-${resource.seoUrl}`}
                    mainImage={resource.mainImage}
                    heading={resource.heading}
                    ingress={resource.ingress}
                    seoUrl={resource.seoUrl}
                    resource_category={resource.resource_category}
                    author_name={resource.authorName}
                    published_at={resource.publishedAt}
                    isShowAuthor={false}
                />
            ));
        }
    };

    return (
        <section className={styles.homepage}>
            <h1 className="screen-reader-only">Noria.no frontpage</h1>
            <div className={styles.backgroundVideoContainer}>
                {(isTablet || isBrowser) && (
                    <video className={`${styles.backgroundVideo}`} autoPlay loop muted playsInline>
                        <source src="/videos/bg-gradient-desktop.mp4" type="video/mp4" />
                    </video>
                )}

                {isMobileOnly && (
                    <video className={`${styles.backgroundVideo}`} autoPlay loop muted playsInline>
                        <source src="/videos/bg-gradient-mobile.mp4" type="video/mp4" />
                    </video>
                )}
            </div>
            <FullWidthContainer>
                <PageHeaderComponent quote="We are a technology partner delivering high-impact digital transformation through our banking and insurance solutions, digital project team and highly experienced consultants">
                    <h1>We specialise in digital and IT for financial services</h1>
                </PageHeaderComponent>
                <DefaultWidthContainer>
                    <div className={styles.links}>
                        <Link href="/solutions" className={`button-link-inverted`}>
                            <span>Our solutions &#10132;</span>
                        </Link>
                        <Link href="/services" className={`button-link`}>
                            <span>Our services &#10132;</span>
                        </Link>
                    </div>
                </DefaultWidthContainer>

                {hasWorks ? (
                    <>
                        <TripleColumnContainer>{renderWorks()}</TripleColumnContainer>
                        <DefaultWidthContainer extraClassNames={styles.workLink}>
                            <Link
                                className={styles.link}
                                href="/resources?type=OUR_WORK&type=CUSTOMER_STORIES"
                            >
                                <span>View all work items</span>
                            </Link>
                        </DefaultWidthContainer>
                    </>
                ) : null}

                {hasResources ? (
                    <>
                        <LargeWidthContainer>
                            <h2 className={styles.subHeader}>Resources</h2>
                        </LargeWidthContainer>
                        <TripleColumnContainer>{renderResources()}</TripleColumnContainer>
                        <DefaultWidthContainer extraClassNames={styles.resourcesLink}>
                            <Link href="/resources" className={styles.link}>
                                <span>View all resources</span>
                            </Link>
                        </DefaultWidthContainer>
                    </>
                ) : null}
            </FullWidthContainer>
        </section>
    );
};

export default HomeComponent;
