import { GetServerSideProps } from 'next';
import React from 'react';
import HomeComponent from '../components/Home/HomeComponent';
import Layout from '../components/Layout/Layout';
import { apiRequest } from '../components/util/requests/requests';
import { PageMetadata } from '../components/util/SeoMetaInformationComponent/SeoMetaInformationComponent';
import { Resource } from './resources';

export interface HomeProps {
    works: Array<Work>;
    resources: Array<Resource>;
    pageMetadata?: PageMetadata;
}

export const getServerSideProps: GetServerSideProps = async ({ params }) => {
    // Works section in home page
    const latestWorks = await apiRequest(
        `/resources?filters[$or][0][resource_category][key][$eq]=OUR_WORK&filters[$or][0][resource_category][key][$eq]=CUSTOMER_STORIES&filters[showOnHomepage][$eq]=true&populate[0]=mainImage&pagination[pageSize]=3`,
        true
    );

    const excludedDisplayWorksParams =
        latestWorks && latestWorks.data && latestWorks.data.length > 0
            ? latestWorks.data
                  .map((latestWork, index) => `&filters[id][$notIn][${index}]=${latestWork.id}`)
                  .join('')
            : '';

    // Resources section in home page
    const resources = await apiRequest(
        `/resources?filters[$and][0][resource_category][key][$neq]=OUR_WORK&[$and][0][resource_category][key][$neq]=CUSTOMER_STORIES&filters[showOnHomepage][$eq]=true&populate[0]=mainImage&pagination[pageSize]=3${excludedDisplayWorksParams}`,
        true
    );

    const pageMetadata = await apiRequest('/meta-pages?filters[key]=HOME_PAGE&populate[0]=seo');

    return {
        props: {
            works: latestWorks.data ?? [],
            resources: resources.data ?? [],
            pageMetadata:
                pageMetadata.data && pageMetadata.data.length > 0 ? pageMetadata.data[0] : null,
        },
    };
};

const Home = ({ works, resources, pageMetadata }: HomeProps) => {
    return (
        <Layout seoMetadata={pageMetadata?.seo}>
            <HomeComponent works={works} resources={resources} />
        </Layout>
    );
};

export default Home;
